import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChaseError, ChaseRequestUID } from '@app/interfaces/payment-tech.interface';
import { BillingStore } from '@app/interfaces/billing-store.interface';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { constants } from './../../@shared/constants';
import { PendoService } from '@app/@shared/services/pendo/pendo.service';

@Injectable({
  providedIn: 'root',
})
export class PayerInfoService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private pendoService: PendoService
  ) {}

  getPaymentTechUID(policyStore: BillingStore, cssURL?: string, consumer: boolean = false): Observable<any> {
    // const hostedSecureID: string = 'cpt539987889165SB';
    // const hostedSecureAPIToken: string = '32233e10f949b6d1c21520f9d96ff7ae';

    let CHASE_HPS_VERSION = 3;
    let payload: ChaseRequestUID = {};
    payload.amount = policyStore.dueToday;
    payload.amount = Number(payload.amount).toFixed(2);
    // console.log('Payment Amount', payload.amount);

    // console.log('policyStore.policyData', policyStore.policyData);

    const orderNum = policyStore.policyData.policy?.policyId
      ? policyStore.policyData.policy?.policyId
      : policyStore.policyData.policy?.polNbr
      ? policyStore.policyData.policy?.polNbr
      : Date.now();

    payload.sessionId = `sess${orderNum}`; //'session_98374943';

    payload.orderId = `pp${orderNum}`; //'pp123456789';

    // console.log('payload.orderId', payload.orderId);

    payload.callbackURL = environment.chaseCallBackUrl;
    payload.transType = 'auth_only';

    if (consumer) {
      payload.formType = '7';
      payload.guid = policyStore.policyData?.policy?.guid;
      payload.productOffering = policyStore.policyData?.referenceData?.product?.productOffering;
    } else {
      payload.productOffering = policyStore.policyData?.referenceData?.product?.productOffering;
      console.log('not sending guid, in agent flow.');
    }
    payload.name = policyStore.policyData.policy.addressDto.insuredName;
    payload.paymentType = policyStore.product === 'ea' ? 'ECP' : 'Credit_Card'; //canopy is echeck only
    cssURL = cssURL
      ? cssURL
      : `https://arch-test-assets.s3.amazonaws.com/arch-chase-billing-portal${
          payload.paymentType === 'ECP' ? '-echeck' : ''
        }.css`;
    payload.cssURL = cssURL;
    // payload.address = '';
    // payload.city = '';
    // payload.state = '';
    if (payload.paymentType === 'Credit_Card') {
      payload.collectAddress = '3';
      payload.zip = policyStore.policyData.policy.addressDto.physicalAddressPostalCode;
    } else {
      payload.collectAddress = '0';
    }

    if (payload.paymentType === 'ECP') {
      CHASE_HPS_VERSION = 2; //not ready for echeck on version 3.
    } else {
      CHASE_HPS_VERSION = 3;
    }

    if (CHASE_HPS_VERSION === 3) {
      payload.transType = 'authOnly';
      payload.hideAcctNum = '2';
      payload.version = CHASE_HPS_VERSION;
      payload.formName = 'arch_cc';
    }

    let apiEndPointURL = environment.billingPortalConsumerApiHost;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    if (!consumer) {
      apiEndPointURL = environment.billingPortalApiHost;
      headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    }

    headers = headers.append('Accept', 'application/json');
    // console.log('environment.archConnectHost + environment.paymentScheduleEndpoint', environment.archConnectHost + environment.paymentScheduleEndpoint);
    return this.http
      .post<any>(
        apiEndPointURL + constants[consumer ? 'initializePaymentConsumerEndPoint' : 'initializePaymentEndPoint'],
        payload,
        { headers }
      )
      .pipe(
        map((uidResult) => {
          // console.log('uidResult', uidResult);
          uidResult.chase_version = CHASE_HPS_VERSION;

          if (uidResult.uid) {
            return { uID: uidResult.uid };
          }

          return uidResult;
          // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
        })
      );
  }

  private paymentMethodMap: any = {
    essentialpl: {
      '1PAY': 'CC',
      '25DP9': 'CREDIT_CARD',
    },
    ea: {
      '1PAY': 'ACH',
      '2PAY60': 'EFT',
      '4PAY40': 'EFT',
    },
  };

  doPostPaymentProcessing(uid: string, policyStore: BillingStore, consumer: boolean = false): Observable<any> {
    let CHASE_HPS_VERSION = 3;

    let headers: HttpHeaders = new HttpHeaders();
    let apiEndPointURL = environment.billingPortalConsumerApiHost;
    headers = headers.append('Content-Type', 'application/json');
    if (!consumer) {
      apiEndPointURL = environment.billingPortalApiHost;
      headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    }
    // headers = headers.append('lob', '');
    // headers = headers.append('product', 'bop');
    headers = headers.append('Accept', 'application/json');

    if (!consumer) {
      const emailId = this.authenticationService.userProfile.email;
      policyStore.policyData.responseJSON.policy.agentEmailId = emailId;
    }

    try {
      policyStore.policyData.responseJSON.policy.paymentMethod =
        this.paymentMethodMap[policyStore.product][policyStore.policyData.responseJSON.policy.Payment_Plan_Cd];
    } catch (e) {
      policyStore.policyData.responseJSON.policy.paymentMethod = policyStore.product === 'ea' ? 'EFT' : 'CREDIT_CARD'; //defaults in case of mapping problems.
    }

    if (
      policyStore.policyData.responseJSON.policy.paymentMethod === 'EFT' ||
      policyStore.policyData.responseJSON.policy.paymentMethod === 'ACH'
    ) {
      CHASE_HPS_VERSION = 2;
    }

    policyStore.policyData.responseJSON.policy.version = CHASE_HPS_VERSION;

    // policyStore.policyData.responseJSON.policy.userChaseSecondaryURL = true;//for targeting the backup Chase endpoint

    this.pendoService.track('paymentSubmitted', policyStore);

    return this.http
      .post<any>(
        apiEndPointURL + constants[consumer ? 'postPaymentConsumerEndpoint' : 'postPaymentEndpoint'] + `/${uid}`,
        policyStore.policyData.responseJSON,
        { headers }
      )
      .pipe(
        map((uidResult) => {
          return uidResult;
          // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
        })
      );
  }

  downloadPolicy(policyStore: BillingStore): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    // headers = headers.append('lob', '');
    // headers = headers.append('product', 'bop');
    headers = headers.append('Accept', 'application/pdf');

    policyStore.policyData.policy.actionCode = 'generatePolicy';

    let product = 'essentialpl';
    if (
      policyStore.policyData.referenceData.product.productOffering &&
      policyStore.policyData.referenceData.product.productOffering == 'MSPPL'
    ) {
      product = 'essentialpl';
    } else {
      product = 'canopy';
    }

    const payload = {
      product: product,
      policyId: policyStore.policyData.policy.policyId,
    };

    return this.http
      .post(environment.billingPortalApiHost + constants.printPolicyEndpoint, payload, {
        headers,
        responseType: 'blob',
      })
      .pipe(
        map((uidResult) => {
          return uidResult;
          // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
        })
      );
  }

  private chaseErrorMap = {
    '52': {
      chaseMsg: 'Processor Decline',
      errorMsg: 'Your payment was declined.',
    },
    '89': {
      chaseMsg: 'Credit Floor.',
      contactSupport: true,
    },
    '100': {
      chaseMsg: 'Merchant Identifier left blank or not valid. The transactions was not processed.',
      contactSupport: true,
    },
    '110': {
      chaseMsg: 'Session Identifier left blank. The transaction was not processsed.',
      contactSupport: true,
    },
    '118': {
      chaseMsg: 'Too many Captcha retries.',
      contactSupport: true,
    },
    '200': {
      chaseMsg: 'Name not present.',
      errorMsg: 'Name field left blank. Please enter the credit card holders name.',
    },
    '300': {
      chaseMsg: 'Amount not specified or invalid value entered.',
      contactSupport: true,
    },
    '310': {
      chaseMsg: 'Credit card number left blank or did not pass Mod10.',
      errorMsg: 'Credit Card Number left blank. Please enter the credit card number.',
    },
    '315': {
      chaseMsg: 'Credit card number did not pass Mod10.',
      errorMsg: 'Credit Card Number invalid. Please enter a valid credit card number.',
    },
    '320': {
      chaseMsg: 'Credit card type left blank or invalid.',
      errorMsg: 'Credit Card Type left blank. Please select Credit Card Type from the dropdown.',
    },
    '330': {
      chaseMsg: 'Expiration month left blank.',
      errorMsg: 'Expiration Month left blank. Please select an Expriation Month from the dropdown.',
    },
    '340': {
      chaseMsg: 'Expiration year left blank.',
      errorMsg: 'Expiration Year left blank. Please select an Expiration Year from the dropdown.',
    },
    '350': {
      chaseMsg: 'CVV2 field submitted but does not match the card.',
      errorMsg: 'CVV2 does not match card. Please enter a valid CVV2 code.',
    },
    '355': {
      chaseMsg: 'CVV2 required but not present.',
      errorMsg: 'CVV2 code missing. Please enter CVV2 code.',
    },
    '357': {
      chaseMsg: 'An invalid character was entered, such as a letter in a numberic field.',
      errorMsg:
        'An invalid character was entered in a numeric field (i.e Credit Card Number, Zip Code, CVV) . Please go back and update.',
    },
    '360': {
      chaseMsg:
        'Payment declined by financial institution, or some other error has occurred (returned from Orbital), such as an invalid message type.',
      errorMsg: 'Your payment was declined.',
    },
    '365': {
      chaseMsg: 'The max_user_retries limit has been reached.',
      contactSupport: true,
    },
    '370': {
      chaseMsg: 'Expiration date invalid.',
      errorMsg: 'Expiration Date invalid. Please enter a valid expiration date.',
    },
    '380': {
      chaseMsg: 'Incorrect CAPTCHA.',
      errorMsg: 'Incorrect CAPTCHA. Please try again.',
    },
    '400': {
      chaseMsg: 'Transaction tracer value does not match.',
      contactSupport: true,
    },
    '500': {
      chaseMsg: 'Address one field required but left blank',
      errorMsg: 'Adress 1 field left blank.',
    },
    '510': {
      chaseMsg: 'City field required but left blank',
      errorMsg: 'City field left blank.',
    },
    '520': {
      chaseMsg: 'State field required but left blank',
      errorMsg: 'State field left blank.',
    },
    '530': {
      chaseMsg: 'ZIP/postal code fields required but left blank',
      errorMsg: 'Zip Code left blank.',
    },
    '531': {
      chaseMsg: 'Invalid ZIP/postal code format received.',
      errorMsg: 'Zip Code format invalid.',
    },
    '550': {
      chaseMsg: 'Country is missing.',
      contactSupport: true,
    },
    '600': {
      chaseMsg: 'Bank name was blank.',
      errorMsg: 'Bank name was left blank. Please enter.',
    },
    '610': {
      chaseMsg: 'Routing Number is blank.',
      errorMsg: 'Routing Number was left blank. Please enter.',
    },
    '620': {
      chaseMsg: 'Checking Account number was blank.',
      errorMsg: 'Checking Account number was left blank. Please enter.',
    },
    '630': {
      chaseMsg: 'Routing Number is invalid.',
      errorMsg: 'Invalid routing number.',
    },
    '640': {
      chaseMsg: 'Routing Number is invalid.',
      errorMsg: 'Invalid routing number.',
    },
    '841': {
      chaseMsg: 'Error validating card/account number range',
      errorMsg: 'Error validating card/account.',
    },
  };

  parseChaseErrors(errCodes: string[]) {
    let chaseErrors: ChaseError[] = [];

    errCodes.forEach((errCode) => {
      if (errCode) {
        const supportMsg = `Please contact Arch Service Center for support. Error Code: ${errCode}`;
        const errInfo = this.chaseErrorMap[errCode];

        let errMsg = supportMsg;
        if (errInfo) {
          errMsg = (errInfo.errorMsg ? errInfo.errorMsg + ' ' : '') + (errInfo.contactSupport ? supportMsg : '');
        }
        chaseErrors.push({
          code: errCode,
          message: errMsg ? errMsg : supportMsg, //if nothing then we will at least provide contact details and the error code.
        });
      }
    });

    return chaseErrors;
  }

  validateUrl(url: string) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  getRedirectUrlWithParams(policyStore: BillingStore) {
    // console.log('atob(this.policyStore.redirectUrl)', atob(this.policyStore.redirectUrl));
    let RedirectURL = new URL(atob(policyStore.redirectUrl));
    RedirectURL.searchParams.set('policy_id', btoa(policyStore.id)); //send them the base64 encoded guid of the mcid.
    // console.log('RedirectURL.href', RedirectURL.href);
    return RedirectURL.href;
  }
}
