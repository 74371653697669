import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationService, UserProfile } from '@app/authentication/authentication.service';
import { ThemingService } from '@shared/services/theming.service';
import { Theme } from '@shared/themes/default.theme';
import { AptFeatureFlagService } from '@archinsurance-da/arch-portal-tools';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { constants } from './../../../@shared/constants';

const PL_PRODUCT = 'essentialpl';
const PL_LOB = 'professionalliability';

@Component({
  selector: 'app-renewal',
  templateUrl: './renewal.component.html',
  styleUrls: ['./renewal.component.scss'],
})
export class RenewalComponent implements OnInit {
  renewalId: string;
  theme: Theme;
  _product: string = PL_PRODUCT;
  _lob: string = PL_LOB;

  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private themeService: ThemingService,
    private ffService: AptFeatureFlagService
  ) {}

  get userProfile(): UserProfile {
    return this.authenticationService.userProfile;
  }

  d2cTheme: Theme;

  renewalForm = this.fb.group({
    q1_radio: ['', Validators.required],
    q2_radio: ['', Validators.required],
    q3_radio: ['', Validators.required],
    revenue: [''],
  });

  ngOnInit() {
    // const currentMaintenances = this.maintenanceService.isScheduledMaintenance();
    // const currentMaint = currentMaintenances[0];
    // if (currentMaint) {
    //   this.router.navigate(['maintenance']);
    // }

    this.themeService.themeChange.subscribe((theme: Theme) => {
      this.theme = theme;

      this.d2cTheme = JSON.parse(JSON.stringify(this.theme));
      this.d2cTheme.agent.phone = this.theme.agent.phoneD2C;
    });

    //get from api and save in the billing store
    const routeParams = this.route.snapshot.paramMap;
    const renewalId = routeParams.get('id');
    const product = routeParams.get('product');
    if (product == 'pl') {
      this._product = PL_PRODUCT;
      this._lob = PL_LOB;
    }

    this.renewalId = renewalId;

    this.renewalForm.get('q3_radio').valueChanges.subscribe((val) => {
      if (val == 'Y') {
        this.renewalForm.controls['revenue'].setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
      } else {
        this.renewalForm.controls['revenue'].clearValidators();
      }
      this.renewalForm.controls['revenue'].updateValueAndValidity();
    });

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('lob', this._lob);
    headers = headers.append('product', this._product);

    this.http
      .get<any>(environment.billingPortalConsumerApiHost + constants.policyStatusEndpoint + this.renewalId, { headers })
      .pipe(
        map((data) => {
          // console.log('data', data);
          return data;
        })
      )
      .subscribe(
        (response: any) => {
          this.renewalFinished = true;
          this.submitting = false;
          if (response?.statusCode === 'B' || response?.statusCode === 'I') {
            this.showRenewalStatusDialog = true;
            this.renewalStatusTitle = 'Unable to Auto Renew';
            if (response?.statusCode === 'B') this.renewalStatusMessage = 'Policy is already bound.';
            else this.renewalStatusMessage = 'Policy is already issued.';
          }
          console.log('response', response);
        },
        (error) => {
          console.warn('renewal error', error);
        }
      );
  }

  submitting: boolean = false;
  renewalFinished: boolean = true;
  renewalTimedOut: boolean = false;
  renewalError: string;
  renewalMessages: string[] = [
    'Your renewal request is being processed.',
    'Your request will be ready shortly. Thank you for your patience.',
  ];

  postRenewalRequest: boolean = false;
  postRenewalTitle: string = '';
  postRenewalMessage: string = '';

  showRenewalStatusDialog: boolean = false;
  renewalStatusTitle: string = '';
  renewalStatusMessage: string = '';

  submitRenewalForm() {
    this.renewalFinished = false;
    this.submitting = true;
    this.renewalError = '';
    // console.log('renewalForm', this.renewalForm.value);

    let payload = {
      id: this.renewalId,
      underwriting_evaluation: {
        questions: [
          {
            code: 'Renew01',
            answer: this.renewalForm.value.q1_radio,
          },
          {
            code: 'Renew02',
            answer: this.renewalForm.value.q2_radio,
          },
          {
            code: 'Renew03',
            answer: this.renewalForm.value.q3_radio,
          },
        ],
      },
    };

    if (this.renewalForm.value.q3_radio === 'Y') {
      payload.underwriting_evaluation.questions.push({
        code: 'Renew04',
        answer: this.renewalForm.value.revenue,
      });
    }

    // console.log('payload', payload);

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('lob', this._lob);
    headers = headers.append('product', this._product);

    this.http
      .post<any>(environment.billingPortalConsumerApiHost + constants.renewalEndpoint, payload, { headers })
      .pipe(
        map((data) => {
          // console.log('data', data);
          return data;
        })
      )
      .subscribe(
        (response: any) => {
          this.renewalFinished = true;
          this.submitting = false;
          if (
            response?.status === 'QUOTED' &&
            response?.detail?.consumerPaymentUrl &&
            response?.detail?.consumerPaymentUrl.includes('archinsurance.com')
          ) {
            this.postRenewalRequest = true;
            this.postRenewalTitle = 'Auto Renewal';
            this.postRenewalMessage = 'So far so good, please wait while take you to the next step.';
            location.href = response?.detail?.consumerPaymentUrl;
          } else if (String(response?.detail).toLowerCase() === 'value not found') {
            this.postRenewalRequest = true;
            this.postRenewalTitle = 'Error Processing Request';
            this.postRenewalMessage =
              'We are unable to automatically quote a renewal for your policy.. The renewal id is invalid.';
          } else {
            //response?.status === 'DECLINED'
            this.postRenewalRequest = true;
            this.postRenewalTitle = 'Unable to Auto Renew';
            this.postRenewalMessage =
              'We are unable to automatically quote a renewal for your policy. Please reach out to your agent if you have any questions.';
          }

          // console.log('response', response);
        },
        (error) => {
          this.postRenewalRequest = true;
          this.postRenewalTitle = 'Unable to Auto Renew';
          this.postRenewalMessage =
            'We are unable to automatically quote a renewal for your policy. Please reach out to your agent if you have any questions.';
          console.warn('renewal error', error);
        }
      );
  }
}
